import request from '../utils/request';

export function teacherList(query) {
	return request({
		url: "teacher_whitelist",
		method: "get",
		params: query
	});
}

export function teacherlistSave(data) {
	return request({
		url: "teacher_whitelist",
		method: "post",
		data: data
	});
}

export function teacherState(query) {
	return request({
		url: 'teacher_whitelist/state',
		method: 'POST',
		data: query
	});
}

export function teacherEnable(query) {
	return request({
		url: 'teacher_whitelist/batch_enable',
		method: 'POST',
		data: query
	});
}
export function teacherDisable(query) {
	return request({
		url: 'teacher_whitelist/batch_disable',
		method: 'POST',
		data: query
	});
}
export function teacherDelete(query) {
	return request({
		url: 'teacher_whitelist/batch_delete',
		method: 'POST',
		data: query
	});
}
export function setInit(query) {
	return request({
		url: 'teacher_whitelist/set_init',
		method: 'POST',
		data: query
	});
}

export function download() {
    return request({
		url: 'teacher_whitelist/download',
        method: 'get',
    })
}

export function uploadUrl(query) {
    return request({
        url: "teacher_whitelist/import",
        method: "post",
        headers:{'Content-Type':'multipart/form-data'},
        data: query
    });
}